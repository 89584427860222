@import '../../../styles/var';

.bannerWrap {
    position: relative;
    background: #092F34;
    padding: 140px 0 95px 0;
    @media(max-width: $md3+px) {
        padding-bottom: 0;
    }
    @media(max-width: $md4+px) {
        padding-top: 120px;
    }
    @media(max-width: $md6+px) {
        padding-top: 110px;
    }
}

.banner {
    position: relative;
    opacity: 0;
    @media(max-width: $md3+px) {
        opacity: 1;
    }
    .content {
        position: relative;
        z-index: 10;
        .title {

        }
        .subtitle {
            color: #FFF;
            margin: 5px 0 40px 0;
            @media(max-width: $md4+px) {
                margin-top: 12px;
            }
            @media(max-width: $md6+px) {
                margin: 6px 0 20px 0;
            }
        }
        .text {
            color: rgba(255, 255, 255, 0.70);
            max-width: 552px;
            @media(max-width: $md3+px) {
                max-width: 700px;
            }
        }
        .btn {
            margin-top: 60px;
            @media(max-width: $md4+px) {
                margin-top: 40px;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 1;
        right: 0;
        top: -20px;
        width: 738px;
        @media(max-width: $md2+px) {
            width: 630px;
            top: 20px;
        }
        @media(max-width: $md3+px) {
            position: relative;
            display: flex;
            top: unset;
            right: unset;
            margin: 60px auto 0 auto;
            width: 653px;
        }
        @media(max-width: 740px) {
            width: 100%;
        }
        @media(max-width: $md6+px) {
            margin-top: 40px;
        }
    }
}
@import '../../../styles/var';

.easySteps {
    position: relative;
    padding-top: 80px;
    @media(max-width: $md4+px) {
        padding-top: 60px;
    }
    .title {
        color: #FFF;
        position: relative;
        z-index: 10;
    }
    .cards {
        position: relative;
        z-index: 10;
        margin-top: 60px;
        display: grid;
        grid-template-columns: repeat(2, 323px);
        grid-template-rows: repeat(2, 1fr);
        gap: 60px;
        @media(max-width: $md4+px) {
            gap: 18px;
            margin-top: 40px;
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: 700px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, auto);
        }
        @media(max-width: $md6+px) {
            margin-top: 20px;
        }
        .card {
            border-radius: 10px;
            background: #024550;
            text-align: center;
            padding: 20px 30px 44px 30px;
            @media(max-width: $md6+px) {
                padding: 20px 30px 25px 30px;
            }
            &Num {
                color: #A3DE1D;
            }
            &Title {
                color: #A3DE1D;
                margin: 12px 0;
            }
            &Text {
                color: rgba(255, 255, 255, 0.70);
            }
        }
    }
}

.easyStepsWrap {
    position: relative;
    .images {
        position: absolute;
        z-index: 1;
        width: 632px;
        top: 185px;
        right: 0;
        @media(max-width: $md2+px) {
            width: 500px;
        }
        @media(max-width: $md3+px) {
            transform: scale(0.8) translateX(200px);
        }
        @media(max-width: $md4+px) {
            transform: translateX(40px);
            position: relative;
            display: flex;
            top: unset;
            right: unset;
            margin-top: 33px;
            margin-left: auto;
            width: 80%;
        }
        @media(max-width: 600px) {
            width: calc(100% + 60px);
            transform: translateX(-35px);
        }
        .image {
            position: relative;
            z-index: 2;
            width: 100%;
        }
        .bg {
            position: absolute;
            z-index: 1;
            top: -200px;
            left: -75px;
            width: 769px;
            @media(max-width: $md2+px) {
                width: 650px;
                left: -30px;
                top: -170px;
            }
            @media(max-width: $md4+px) {
                left: 0;
                top: -100px;
            }
            @media(max-width: 600px) {
                width: 500px;
                left: -50px;
            }
        }
    }
}
@import "../../../styles/var";
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@include keyframes(top-anim1) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -15px);
    }
}
@include keyframes(top-anim2) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 25px);
    }
}
@include keyframes(top-anim3) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 15px);
    }
}
@include keyframes(top-anim4) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -15px);
    }
}
.more {
}
.moreBody {
}
.moreRelative {
    position: relative;
}
.moreTitle {
    text-align: center;
    margin-bottom: 50px;
    @media (max-width: $md5+px) {
        margin-bottom: 30px;
    }
}
.moreTable {
}
.moreTableRow {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    max-width: 1084px;
    margin: 0 auto;
    padding: 10px 160px;
    @media (max-width: $md3+px) {
        padding: 10px 100px;
    }
    @media (max-width: 1030px) {
        padding: 10px 0px;
    }
}
.moreTableRowLeft {
    flex: 0 1 312px;
    @media (max-width: $md5+px) {
        flex: 0 1 49%;
        font-size: 14px !important;
    }
}
.moreTableRowRight {
    flex: 0 1 367px;
    font-weight: 400 !important;
    @media (max-width: $md5+px) {
        flex: 0 1 49%;
        font-size: 14px !important;
    }
}
.moreImg1 {
    position: absolute;
    left: 10px;
    top: 110px;
    width: 105.665px;
    height: 101.556px;
    transform: translate(0, 200px);
    @media (max-width: $md3+px) {
        transform: translate(0, 0px);
    }
    @media (max-width: $md3+px) {
        display: none;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim1 linear 1.7s alternate infinite");
    }
}
.moreImg2 {
    position: absolute;
    left: 120px;
    top: 320px;
    width: 65.665px;
    height: 81.556px;
    transform: translate(0, 200px);
    @media (max-width: $md3+px) {
        transform: translate(0, 0px);
    }
    @media (max-width: $md3+px) {
        display: none;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim2 linear 1.7s alternate infinite");
    }
}
.moreImg3 {
    position: absolute;
    right: 45px;
    top: 80px;
    width: 95.665px;
    height: 91.556px;
    transform: translate(0, 200px);
    @media (max-width: $md3+px) {
        transform: translate(0, 0px);
    }
    @media (max-width: $md3+px) {
        display: none;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim3 linear 1.7s alternate infinite");
    }
}
.moreImg4 {
    position: absolute;
    right: 95px;
    top: 300px;
    width: 95.665px;
    height: 91.556px;
    transform: translate(0, 200px);
    @media (max-width: $md3+px) {
        transform: translate(0, 0px);
    }
    @media (max-width: $md3+px) {
        display: none;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        @include animation("top-anim4 linear 1.7s alternate infinite");
    }
}

@import '../../../styles/var';

.tradingStocks {
    position: relative;
    .title {
        position: relative;
        z-index: 10;
    }
    .tabs {
        position: relative;
        z-index: 10;
        margin-top: 40px;
        .tab {
            position: relative;
            padding: 20px 0 20px 40px;
            cursor: pointer;
            @media(max-width: $md6+px) {
                padding: 10px 0 10px 20px;
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 100%;
                background: rgba(0, 0, 0, 0.10);
            }
            &Active {
                .tabText {
                    display: block;
                }
                &::before {
                    width: 2px;
                    background: #024550;
                }
            }
            &Title {
                font-weight: 500;
            }
            &Text {
                display: none;
                margin-top: 12px;
                max-width: 377px;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 495px;
        @media(max-width: $md2+px) {
            width: 420px;
            top: 50px;
        }
        @media(max-width: $md3+px) {
            width: 350px;
            top: 100px;
        }
        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 50px auto 0 auto;
            top: unset;
            right: unset;
        }
        @media(max-width: $md6+px) {
            width: 286px;
        }
    }
}
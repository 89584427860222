@import '../../../styles/var';

.faq {
    position: relative;
    .title {
        text-align: center;
    }
    .tabs {
        margin-top: 40px;
        @media(max-width: $md6+px) {
            margin-top: 30px;
        }
        .tab {
            position: relative;
            cursor: pointer;
            padding: 20px 110px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.10);
            @media(max-width: $md3+px) {
                padding: 20px 60px;
            }   
            @media(max-width: $md4+px) {
                padding: 20px;
            }
            @media(max-width: $md6+px) {
                padding: 15px 0;
            }
            &:nth-last-child(1) {
                border-bottom: none;
            }
            &Active {
                .tabStatus {
                    opacity: 0.4;
                    &::after {
                        transform: rotate(90deg);
                    }
                }
                .tabContent {
                    display: block;
                }
            }
            &Header {
                display: grid;
                grid-template-columns: 1fr 30px;
                gap: 60px;
                align-items: center;
                .tabTitle {
                    color: #024550;
                    font-weight: 400;
                    font-family: $manrope!important;
                }
                .tabStatus {
                    position: relative;
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    background: #A3DE1D;
                    transition: all .2s;
                    @media(max-width: $md6+px) {
                        transform: scale(0.8);
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        width: 14px;
                        height: 2px;
                        top: calc(50% - 1px);
                        left: calc(50% - 7px);
                        background: #092F34;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        width: 2px;
                        height: 14px;
                        top: calc(50% - 7px);
                        left: calc(50% - 1px);
                        background: #092F34;
                        transition: all .2s;
                    }
                }
            }
            &Content {
                display: none;
                margin-top: 20px;
                max-width: 80%;
                @media(max-width: $md4+px) {
                    max-width: 90%;
                }
                @media(max-width: $md6+px) {
                    margin-top: 10px;
                }
            }
        }
    }
}
@import '../../../styles/var';

.block {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    @media(max-width: $md4+px) {
        justify-content: unset;
        align-items: unset;
        flex-direction: column-reverse!important;
        gap: 60px;
    }
    @media(max-width: $md6+px) {
        gap: 40px;
    }
    &Reverse {
        flex-direction: row-reverse;
        .image {
            margin-left: 0!important;
            margin-right: 20px;
            @media(max-width: $md3+px) {
                margin-right: 0;
            }
            @media(max-width: $md4+px) {
                margin: 0 auto!important;
            }
        }
    }
    .content {
        position: relative;
        z-index: 10;
        width: 522px;
        @media(max-width: $md4+px) {
            width: 100%;
        }
        .title {

        }
        .text {
            margin-top: 40px;
            @media(max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }
    .image {
        position: relative;
        z-index: 1;
        margin-left: 50px;
        @media(max-width: $md3+px) {
            margin-left: 0;
        }
        @media(max-width: $md4+px) {
            display: flex;
            margin: 0 auto;
        }
        &_01 {
            width: 467px;
            @media(max-width: $md2+px) {
                width: 420px;
            }
            @media(max-width: $md3+px) {
                width: 350px;
            }
            @media(max-width: $md4+px) {
                width: 373px;
            }
            @media(max-width: $md6+px) {
                width: 280px;
            }
        }
        &_02 {
            width: 477px;
            @media(max-width: $md2+px) {
                width: 420px;
            }
            @media(max-width: $md3+px) {
                width: 350px;
            }
            @media(max-width: $md4+px) {
                width: 381px;
            }
            @media(max-width: $md6+px) {
                width: 286px;
            }
        }
        &_03 {
            width: 449px;
            @media(max-width: $md2+px) {
                width: 420px;
            }
            @media(max-width: $md3+px) {
                width: 350px;
            }
            @media(max-width: $md4+px) {
                width: 361px;
            }
            @media(max-width: $md6+px) {
                width: 262px;
            }
        }
        &_04 {
            width: 466px;
            @media(max-width: $md2+px) {
                width: 420px;
            }
            @media(max-width: $md3+px) {
                width: 350px;
            }
            @media(max-width: $md4+px) {
                width: 379px;
            }
            @media(max-width: $md6+px) {
                width: 274px;
            }
        }
        &_05 {
            width: 492px;
            @media(max-width: $md2+px) {
                width: 420px;
            }
            @media(max-width: $md3+px) {
                width: 350px;
            }
            @media(max-width: $md4+px) {
                width: 373px;
            }
            @media(max-width: $md6+px) {
                width: 280px;
            }
        }
        &_06 {
            width: 456px;
            @media(max-width: $md2+px) {
                width: 420px;
            }
            @media(max-width: $md3+px) {
                width: 350px;
            }
            @media(max-width: $md4+px) {
                width: 386px;
            }
            @media(max-width: $md6+px) {
                width: 280px;
            }
        }
    }
}
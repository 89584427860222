@import "../../../styles/var";

.block {
    overflow: hidden;
    position: relative;
    z-index: 4;
    @media (max-width: 520px) {
        padding-top: 20px !important;
    }
}
.blockImg {
    width: 12px;
    height: 339px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    margin: auto;
    display: flex;
    justify-content: center;
    transform: translate(0, 200px) scale(0.8);
    @media (max-width: $md3+px) {
        transform: translate(0, 0px) scale(1);
    }
    img {
        object-fit: contain;
        object-position: left bottom;
        width: 1032px;
        height: 339px;
        @media (max-width: $md5+px) {
            width: 732px;
            height: 339px;
        }
        @media (max-width: 600px) {
            width: 532px;
        }
    }
}
.block {
}
.blockBody {
    padding-bottom: 134px;
    @media (max-width: 840px) {
        padding-bottom: 110px;
    }
    @media (max-width: $md5+px) {
        padding-bottom: 80px;
    }
}
.blockRow {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 3;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        gap: 15px 0px;
    }
}
.blockRowColumn {
    transform: translate(0, 200px);
    @media (max-width: $md3+px) {
        transform: translate(0, 0px);
    }
    flex: 0 1 323px;
    padding: 20px 20px 53px 20px;
    background: rgba(2, 69, 80, 0.8);
    border-radius: 10px;
    @media (max-width: $md2+px) {
        flex: 0 1 290px;
        padding: 20px 10px 43px 10px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 49%;
        padding: 20px 20px 43px 20px;
    }
    @media (max-width: 690px) {
        flex: 0 1 100%;
    }
}
.blockRowColumnItem {
}
.blockRowColumnItemImg {
    margin-bottom: 40px;
}
.blockRowColumnItemTitle {
    margin-bottom: 12px;
    color: #a3de1d !important;
}
.blockRowColumnItemText {
    color: #b4c7c8 !important;
}

@import '../../../styles/var';

.bannerWrap {
    position: relative;
    overflow: hidden;
    background: #092F34;
}

.banner {
    position: relative;
    padding: 140px 0;
    opacity: 0;
    @media(max-width: $md3+px) {
        opacity: 1;
    }
    @media(max-width: $md4+px) {
        padding: 120px 0 45px 0;
    }
    @media(max-width: $md6+px) {
        padding-top: 110px;
    }
    .content {
        position: relative;
        z-index: 10;
        .title {
            color: #FFF;
        }
        .text {
            color: rgba(255, 255, 255, 0.70);
            margin-top: 40px;
            max-width: 552px;
            @media(max-width: $md3+px) {
                max-width: 700px;
            }
            @media(max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 76px;
        width: 522px;
        @media(max-width: $md2+px) {
            width: 500px;
        }
        @media(max-width: $md3+px) {
            display: flex;
            position: relative;
            top: unset;
            right: unset;
            margin: 40px auto 0 auto;
            width: 100%;
            max-width: 522px;
        }
        @media(max-width: $md6+px) {
            width: 335px;
        }
    }
}
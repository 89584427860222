@import '../../../styles/var';

.whatIs {
    position: relative;
    .content {
        position: relative;
        z-index: 10;
        .title {

        }
        .text {
            margin-top: 40px;
            max-width: 552px;
            @media(max-width: $md4+px) {
                max-width: 700px;
            }
            @media(max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        width: 503px;
        right: 80px;
        @media(max-width: $md2+px) {
            width: 450px;
            right: 40px;
            top: 40px;
        }
        @media(max-width: $md3+px) {
            width: 350px;
            right: 0;
            top: 80px;
        }
        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 60px auto 0 auto;
            right: unset;
            top: unset;
        }
        @media(max-width: $md6+px) {
            margin-top: 40px;
            width: 250px;
        }
    }
}
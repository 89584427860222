@import "../../../styles/var";

.banner {
    background-color: #092f34;
    padding: 140px 0 60px 0;
    position: relative;
}
.bannerBody {
}
.bannerRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.bannerRowLeft {
    flex: 0 1 600px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 100px;
    }
}
.bannerRowLeftTitle {
    margin-bottom: 28px;
    @media (max-width: $md3+px) {
        max-width: 700px;
    }
    @media (max-width: $md4+px) {
        max-width: 500px;
    }
}
.bannerRowLeftText {
    max-width: 552px;
    margin-bottom: 20px;
    color: #b5c0c2 !important;
    @media (max-width: $md3+px) {
        max-width: 100%;
    }
}
.bannerRowLeftBtn {
    margin-top: 40px;
}
.bannerRowRight {
    flex: 0 1 552px;
    @media (max-width: $md2+px) {
        flex: 0 1 500px;
    }
    display: flex;
    @media (max-width: $md6+px) {
        flex-wrap: wrap;
    }
}
.bannerRowRightBtns {
    @media (max-width: $md6+px) {
        flex: 0 1 100%;
        display: flex;
    }
}
.bannerRowRightBtnsBtn {
    width: 66px;
    height: 66px;
    @media (max-width: $md6+px) {
        width: 48px;
        height: 48px;
        img {
            width: 20px;
            height: 20px;
        }
    }
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: all 0.3s linear;
    border: 1px solid #024550;
    &.active {
        background-color: #024550;
    }
}
.bannerRowRightContent {
    padding: 20px 70px 20px 70px;
    background: #024550;
    flex: 0 1 486px;
    @media (max-width: $md2+px) {
        padding: 20px 30px 20px 30px;
        flex: 0 1 450px;
    }
    @media (max-width: $md6+px) {
        padding: 20px 20px 20px 20px;
    }
}
.bannerRowRightContentTab {
    display: none;

    &.active {
        display: block;
    }
}
.bannerRowRightContentTabTitle {
    text-align: center;
    color: #fff !important;
    margin-bottom: 30px;
}
.bannerRowRightContentTabGreyTexts {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.bannerRowRightContentTabGreyTextsLeft {
    color: #b3c7ca !important;
    flex: 0 1 180px;
}
.bannerRowRightContentTabGreyTextsRight {
    flex: 0 1 100px;
    color: #b3c7ca !important;
}
.bannerRowRightContentTabBtn {
    margin-top: 40px;
    text-align: center;
}

@import '../../../styles/var';
@import '../../../styles/mixins';

.bannerWrap {
    position: relative;
    background: #092F34;
    overflow: hidden;
}

.banner {
    position: relative;
    padding: 140px 0;
    opacity: 0;
    @media(max-width: $md3+px) {
        opacity: 1;
    }
    @media(max-width: $md4+px) {
        padding: 120px 0 90px 0;
    }
    @media(max-width: $md6+px) {
        padding: 110px 0 70px 0;
    }
    .content {
        position: relative;
        z-index: 10;
        .title {

        }
        .subtitle {
            color: #FFF;
            margin: 5px 0 40px 0;
            @media(max-width: $md4+px) {
                margin-top: 12px;
            }
            @media(max-width: $md6+px) {
                margin: 10px 0 20px 0;
            }
        }
        .text {
            max-width: 552px;
            color: rgba(255, 255, 255, 0.70);
            @media(max-width: $md3+px) {
                max-width: 700px;
            }
        }
        .btn {
            margin-top: 60px;
            @media(max-width: $md4+px) {
                margin-top: 40px;
            }
        }
    }
    .images {
        position: absolute;
        z-index: 1;
        top: 224px;
        right: 70px;
        width: 526px;
        @media(max-width: $md2+px) {
            right: 0;
            transform: scale(0.8);
        }
        @media(max-width: $md3+px) {
            right: unset;
            top: unset;
            transform: none;
            display: flex;
            margin: 138px auto 0 auto;
            position: relative;
        }
        @media(max-width: 740px) {
            margin-top: 108px;
            width: 310px;
        }
        .phones {
            width: 100%;
            position: relative;
            z-index: 3;
        }
        .bg {
            position: absolute;
            z-index: 1;
            top: -225px;
            left: -95px;
            width: 769px;
            @media(max-width: 740px) {
                top: -134px;
                left: -56px;
                width: 440px;
            }
        }
        .coinWrap {
            position: absolute;
            &_01 {
                z-index: 4;
                left: -37px;
                bottom: 141px;
                width: 124px;
                @media(max-width: 740px) {
                    width: 72px;
                    left: -22px;
                    bottom: 87px;
                }
                img {
                    @include animation("levitating-1 ease-in-out 3s alternate infinite");
                }
            }
            &_02 {
                z-index: 2;
                width: 78px;
                top: 11px;
                left: -5px;
                @media(max-width: 740px) {
                    width: 46px;
                    left: -3px;
                    top: 7px;
                }
                img {
                    @include animation("levitating-1 ease-in-out 2.5s alternate infinite");
                }
            }
            &_03 {
                z-index: 4;
                width: 110px;
                top: -51px;
                right: 139px;
                @media(max-width: 740px) {
                    width: 64px;
                    top: -30px;
                    right: 82px;
                }
                img {
                    @include animation("levitating-2 ease-in-out 3s alternate infinite");
                }
            }
            &_04 {
                z-index: 2;
                width: 60px;
                top: 11px;
                right: 0;
                @media(max-width: 740px) {
                    width: 30px;
                    top: 7px;
                }
                img {
                    @include animation("levitating-1 ease-in-out 2s alternate infinite");
                }
            }
            &_05 {
                z-index: 2;
                width: 127px;
                bottom: 135px;
                right: -31px;
                @media(max-width: 740px) {
                    width: 74px;
                    right: -18px;
                    bottom: 75px;
                }
                img {
                    @include animation("levitating-1 ease-in-out 3s alternate infinite");
                }
            }
            .coin {
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
    }
}
@import '../../../styles/var';

.bonds {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    @media(max-width: $md4+px) {
        flex-direction: column;
        align-items: unset;
        justify-content: unset;
    }
    @media(max-width: $md6+px) {
        gap: 40px;
    }
    .content {
        position: relative;
        z-index: 10;
        width: 552px;
        @media(max-width: $md4+px) {
            width: 100%;
        }
        .title {

        }
        .text_01 {
            margin: 20px 0 80px 0;
            max-width: 700px;
        }
        .subtitle {

        }
        .text_02 {
            margin-top: 40px;
            max-width: 700px;
            @media(max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }
    .image {
        position: relative;
        width: 616px;
        @media(max-width: $md2+px) {
            width: 500px;
        }
        @media(max-width: $md3+px) {
            width: 400px;
        }
        @media(max-width: $md4+px) {
            width: 500px;
            display: flex;
            margin: 0 auto;
        }
        @media(max-width: 600px) {
            width: 320px;
        }
    }
}
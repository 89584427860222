@import "../../../styles/var";

.award {
}
.awardBody {
}
.awardRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.awardRowLeft {
    flex: 0 1 552px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 60px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 40px;
    }
}
.awardRowLeftTitle {
    margin-bottom: 40px;
    @media (max-width: $md6+px) {
        margin-bottom: 20px;
    }
}
.awardRowLeftText {
    margin-bottom: 20px;
    @media (max-width: $md6+px) {
        margin-bottom: 12px;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
.awardRowRight {
    flex: 0 1 475px;

    position: relative;
    transform: translate(0, 200px);
    @media (max-width: $md3+px) {
        transform: translate(0, 0);
    }

    img {
        width: 100%;
    }
}

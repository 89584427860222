@import "../../../styles/var";

.typesBonds {
    position: relative;
    .title {
        margin-bottom: 60px;
        @media (max-width: $md4+px) {
            margin-bottom: 40px;
        }
        @media (max-width: $md5+px) {
            max-width: 280px;
        }
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;
        @media (max-width: $md4+px) {
            display: none;
        }
    }
    .card {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 0px 24px 0px rgba(207, 207, 207, 0.25);
        padding: 20px 30px 35px 30px;
        @media (max-width: $md6+px) {
            padding: 16px 16px 20px 16px;
        }
        &Icon {
            width: 50px;
            @media (max-width: $md6+px) {
                width: 40px;
            }
        }
        &Title {
            margin: 20px 0 12px 0;
        }
        &Text {
        }
    }
    .swiperWrap {
        display: none;
        @media (max-width: $md4+px) {
            display: block;
        }
    }
    :global(.homeTypesBondsSwiper) {
        height: 100%;
        overflow: visible;
        padding-bottom: 40px;
    }
    :global(.homeTypesBondsSwiperSlide) {
        height: auto !important;
    }
    :global(.swiper-pagination) {
        bottom: 0;
    }
    :global(.swiper-pagination-bullet) {
        background: #dfdfdf;
        opacity: 1;
        transition: all 0.3s;
    }
    :global(.swiper-pagination-bullet-active) {
        background: #a3de1d;
    }
}

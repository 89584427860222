@import "../../../styles/var";

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@include keyframes(top-anim1) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -15px);
    }
}
@include keyframes(top-anim2) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 25px);
    }
}
@include keyframes(top-anim3) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 15px);
    }
}
@include keyframes(top-anim4) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -15px);
    }
}

.bannerBody {
    padding-top: 140px;
    @media (max-width: $md3+px) {
        padding-top: 120px;
    }
    @media (max-width: $md5+px) {
        padding-top: 110px;
    }
}
.bannerRow {
    display: flex;
    justify-content: space-between;
}
.bannerRowLeft {
    flex: 0 1 552px;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.bannerRowLeftTitle {
    color: #fff !important;
    margin-bottom: 40px;
    @media (max-width: $md6+px) {
        margin-bottom: 20px;
    }
}
.bannerRowLeftText {
    color: rgba(255, 255, 255, 0.7) !important;
    margin-bottom: 20px;
    @media (max-width: $md6+px) {
        margin-bottom: 12px;
    }
}
.bannerRowLeftBtn {
    margin-top: 60px;
    @media (max-width: $md5+px) {
        margin-top: 40px;
    }
}
.bannerRowRight {
    position: relative;
}
.bannerRowRightImgs {
    width: 810px;
    height: 840px;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    @media (max-width: $md2+px) {
        right: -50px;
    }
    @media (max-width: $md3+px) {
        display: none;
    }
}
.bannerRowRightImgsImg1 {
    position: absolute;
    top: 100px;
    right: 590px;
    width: 98px;
    height: 98px;
    transform: translate(-20px, 20px) rotate(-35deg);
    opacity: 0;
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        @include animation("top-anim1 linear 1.7s alternate infinite");
    }
}
.bannerRowRightImgsImg2 {
    position: absolute;
    top: -10px;
    right: 440px;
    width: 110px;
    height: 110px;
    transform: translate(20px, -20px) rotate(35deg);
    opacity: 0;
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        @include animation("top-anim2 linear 1.7s alternate infinite");
    }
}
.bannerRowRightImgsImg3 {
    position: absolute;
    top: 210px;
    right: 50px;
    width: 138px;
    height: 138px;
    transform: translate(-20px, 20px) rotate(-35deg);
    opacity: 0;
    img {
        @include animation("top-anim3 linear 1.7s alternate infinite");
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerRowRightImgsImg4 {
    position: absolute;
    top: 400px;
    right: 170px;
    width: 108px;
    height: 108px;
    opacity: 0;
    transform: translate(-20px, 20px) rotate(-35deg);

    img {
        @include animation("top-anim4 linear 1.7s alternate infinite");
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerRowRightImgsImg5 {
    position: absolute;
    top: -140px;
    right: -140px;
    width: 1208px;
    height: 1208px;
    opacity: 0;
    transform: translate(-20px, 20px);
    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.bannerRowRightImgsImg6 {
    position: absolute;
    top: 0;
    right: 90px;
    width: 908px;
    height: 908px;
    opacity: 0;
    transform: translate(-100px, 100px);

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}

.mob {
    position: relative;
}
.banner {
    @media (max-width: $md3+px) {
        padding-bottom: 550px !important;
    }
    @media (max-width: 840px) {
        padding-bottom: 390px !important;
    }
    @media (max-width: 520px) {
        padding-bottom: 0 !important;
    }
}
.bannerMob {
    display: none;
    @media (max-width: $md3+px) {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 70px;
        margin: auto;
        text-align: center;
    }
    @media (max-width: 520px) {
        display: none;
    }
    @media (max-width: 840px) {
        img {
            width: 600px;
            height: 600px;
        }
    }
    @media (max-width: 630px) {
        img {
            width: 500px;
            height: 500px;
            object-fit: contain;
        }
    }
}
.bannerMob1 {
    display: none;
    @media (max-width: 520px) {
        text-align: center;
        display: block;
        img {
            max-width: 100%;
        }
    }
}

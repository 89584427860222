@import "../../../styles/var";

.fit {
}
.fitBody {
}
.fitTitle {
    margin-bottom: 40px;
    text-align: center;
    @media (max-width: $md5+px) {
        text-align: left;
    }
}
.fitTabs {
    max-width: 381px;
    margin: 0 auto 55px auto;
    display: flex;
    border-radius: 40px;
    background: #f3f3f3;
    padding: 4px;
    justify-content: space-between;
}
.fitTabsTab {
    border-radius: 40px;
    padding: 8px 50px;
    cursor: pointer;
    transition: all 0.3s linear;
    color: #092f34 !important;
    &.active {
        background: #a3de1d;
    }
}
.active {
}
.fitRow {
    display: none;
    &.active {
        display: flex;
    }
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.fitRowLeft {
    flex: 0 1 606px;
    @media (max-width: 1150px) {
        flex: 0 1 506px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
        margin-bottom: 60px;
    }
}
.fitRowLeftTitle {
    margin-bottom: 20px;
}
.fitRowLeftText {
}
.fitRowRight {
    flex: 0 1 350px;
    img {
        width: 100%;
    }
}

@import '../../../styles/var';

.howWorks {
    position: relative;
    .title {
        text-align: center;
    }
    .cards {
        margin-top: 60px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 20px 170px;
        @media(max-width: $md2+px) {
            gap: 20px 120px;
        }
        @media(max-width: $md3+px) {
            gap: 20px 80px;
        }
        @media(max-width: $md4+px) {
            margin-top: 40px;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, auto);
            gap: 12px;
        }
        @media(max-width: $md6+px) {
            margin-top: 20px;
            gap: 20px;
        }
        .card { 
            position: relative;
            display: grid;
            grid-template-columns: 170px 1fr;
            gap: 40px;
            align-items: center;
            @media(max-width: 600px) {
                grid-template-columns: 120px 1fr;
            }
            @media(max-width: $md6+px) {
                display: flex;
                flex-direction: column;
                align-items: unset;
                gap: 10px;
            }
            &:hover {
                .cardImage {
                    transform: scale(0.96);
                }
            }
            &Image {
                transition: all .3s;
                @media(max-width: $md6+px) {
                    width: 120px;
                }
                img {
                    width: 100%;
                }
            }
            &Content {
                .cardTitle {
                    font-weight: 500;
                }
                .cardText {
                    margin-top: 12px;
                }
            }
        }
    }
}
@import "../../../styles/var";

.deposit {
}
.depositBody {
}
.depositTitle {
    max-width: 610px;
    margin: 0 auto 60px auto;
    text-align: center;
    @media (max-width: $md5+px) {
        max-width: 510px;
    }
    @media (max-width: $md6+px) {
        font-size: 20px;
        max-width: 350px;
        margin: 0 auto 40px auto;
    }
}
.depositRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
        max-width: 500px;
        margin: 0 auto;
        gap: 40px 0px;
    }
    @media (max-width: $md5+px) {
        max-width: 450px;
    }
    @media (max-width: $md6+px) {
        max-width: 350px;
    }
}
.depositRowItem {
    &:hover {
        img {
            transform: scale(1.05);
        }
        cursor: pointer;
    }
    img {
        transition: all 0.3s linear;
        @media (max-width: $md5+px) {
            height: 55px;
        }
        @media (max-width: $md6+px) {
            height: 45px;
        }
    }
}

@import '../../../styles/var';

.readyTo {
    position: relative;
    .title {
        text-align: center;
    }
    .block {
        display: grid;
        grid-template-columns: 1fr 336px 1fr;
        justify-items: center;
        gap: 60px;
        margin-top: 60px;
        @media(max-width: $md2+px) {
            grid-template-columns: 1fr 300px 1fr;
        }
        @media(max-width: $md4+px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: $md5+px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .content {
            text-align: center;
            padding-top: 70px;
            @media(max-width: $md4+px) {
                padding-top: 0;
            }
            &_01 {
                @media(max-width: $md4+px) {
                    grid-row-start: 1;
                    grid-row-end: 1;
                    grid-column-start: 1;
                    grid-column-end: 1;
                }
                @media(max-width: $md5+px) {
                    grid-row-start: unset;
                    grid-row-end: unset;
                    grid-column-start: unset;
                    grid-column-end: unset;
                }
            }
            &_02 {
                @media(max-width: $md4+px) {
                    grid-row-start: 1;
                    grid-row-end: 1;
                    grid-column-start: 2;
                    grid-column-end: 2;
                }
                @media(max-width: $md5+px) {
                    grid-row-start: unset;
                    grid-row-end: unset;
                    grid-column-start: unset;
                    grid-column-end: unset;
                }
            }
            .text {
                max-width: 300px;
                margin: 0 auto;
                @media(max-width: $md6+px) {
                    width: 245px;
                }
            }
            .btn {
                margin-top: 40px;
            }
        }
        img {
            width: 100%;
            @media(max-width: $md4+px) {
                width: 260px;
                grid-row-start: 2;
                grid-row-end: 2;
                transform: translateX(calc(100% - 30px));
            }
            @media(max-width: $md5+px) {
                grid-row-start: 3;
                grid-row-end: 3;
                transform: none;
            }
            @media(max-width: $md6+px) {
                width: 190px;
            }
        }
    }
}